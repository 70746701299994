@import './utility/variables';

form {
  margin-top: 65px;
}

.form-divider {
  max-width: 1024px;
  margin-left: 0;
}

.form-header {
  color: $primary-color;
  max-width: 900px;
  margin: 30px 0 25px;
  border-bottom: 1px #ddd solid;
  padding: 0 0 15px 10px;
}

.form-subheader {
  color: #777;
  font-size: 14px;
  margin: -18px 0 25px;
  padding: 0 0 5px 10px;
}

.expandable-header {
  color: $primary-color;
  cursor: pointer;

  span {
    vertical-align: middle;
  }

  .mat-icon {
    vertical-align: middle;
    line-height: 25px;
  }
}

form.project-form {
  padding: 0 25px;
  margin-top: 2rem;
}


