/* stylelint-disable */

@use '@angular/material' as mat;
@import './utility/variables';
@import './mixin';

$font: var(--font);

$font-config: mat.define-typography-config(
        $font-family: $font,
        $headline-1: mat.define-typography-level(112px, 112px, 300, $font, -0.0134em),
        $headline-2: mat.define-typography-level(56px, 56px, 400, $font, -0.0089em),
        $headline-3: mat.define-typography-level(45px, 48px, 400, $font, 0em),
        $headline-4: mat.define-typography-level(34px, 40px, 400, $font, 0.0074em),
        $headline-5: mat.define-typography-level(24px, 32px, 400, $font, 0em),
        $headline-6: mat.define-typography-level(20px, 32px, 500, $font, 0.0075em),
        $subtitle-1: mat.define-typography-level(16px, 28px, 400, $font, 0.0094em),
        $subtitle-2: mat.define-typography-level(15px, 24px, 500, $font, 0.0067em),
        $body-1: mat.define-typography-level(14px, 24px, 500, $font, 0.0179em),
        $body-2: mat.define-typography-level(14px, 20px, 400, $font, 0.0179em),
        $button: mat.define-typography-level(14px, 14px, 500, $font, 0.0893em),
        $caption: mat.define-typography-level(12px, 20px, 400, $font, 0.0333em),
        $overline: mat.define-typography-level(inherit, 1.125, 400, $font, 1.5px),
);

@include mat.core();
@include mat.legacy-core();
//@include custom-stepper-theme();

$dark-text: #333;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba(rgba(black, 0.87), 0.54);
$dark-disabled-text: rgba(rgba(black, 0.87), 0.38);
$dark-dividers: rgba(rgba(black, 0.87), 0.12);
$dark-focused: rgba(rgba(black, 0.87), 0.12);

$light-theme-foreground-palette: (
  base: black,
  divider: rgba(black, 0.12),
  dividers: rgba(black, 0.12),
  disabled: rgba(black, 0.38),
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: rgba(black, 0.38),
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: rgba(black, 0.38),
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: rgba(black, 0.87),
  slider-min: rgba(black, 0.87),
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: rgba(black, 0.38),
);

$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$dark-theme-foreground-palette: (
  base: $light-text,
  divider: rgba(white, 0.12),
  dividers: rgba(white, 0.12),
  disabled: rgba(white, 0.5),
  disabled-button: rgba($light-text, 0.3),
  disabled-text: rgba(white, 0.5),
  elevation: black,
  hint-text: rgba(white, 0.5),
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$light-theme-background-palette: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: rgba(black, 0.12),
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

$dark-theme-background-palette: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: rgba(white, 0.12),
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

body {
  --text-primary-color: #{white};
  --text-primary-lighter-color: #{rgba(black, 0.87)};
  --text-primary-darker-color: #{white};
}

$mat-primary: (
  50 : #e9e4ef,
  100 : #c8bcd8,
  200 : #a38fbe,
  300 : #7e62a4,
  400 : #624091,
  500 : #461e7d,
  600 : #3f1a75,
  700 : #37166a,
  800 : #2f1260,
  900 : #200a4d,
  A100 : #a583ff,
  A200 : #8050ff,
  A400 : #5b1dff,
  A700 : #4903ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);
$theme-primary: mat.define-palette($mat-primary);

body {
  --text-accent-color: #{white};
  --text-accent-lighter-color: #{rgba(black, 0.87)};
  --text-accent-darker-color: #{white};
}

$theme-accent: mat.define-palette($mat-primary);

body {
  --text-warn-color: #{white};
  --text-warn-lighter-color: #{rgba(black, 0.87)};
  --text-warn-darker-color: #{white};
}

$mat-warn: (
  50 : #fce5e8,
  100 : #f7bec5,
  200 : #f2929f,
  300 : #ed6678,
  400 : #e9465b,
  500 : #e5253e,
  600 : #e22138,
  700 : #de1b30,
  800 : #da1628,
  900 : #d30d1b,
  A100 : #ffffff,
  A200 : #ffcccf,
  A400 : #ff999e,
  A700 : #ff8086,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);
$theme-warn: mat.define-palette($mat-warn);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography: $font-config,
    density: 0,
  )
);

$altTheme: mat.define-dark-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography: $font-config,
    density: 0,
  )
);

@include mat.all-component-themes($theme);
@include mat.all-legacy-component-themes($theme);

.theme-alternate {
  @include mat.all-component-colors($altTheme);
  @include mat.all-legacy-component-colors($altTheme);
}

/** CUSTOM **/

.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.mat-expansion-panel-header .mat-content {
  display: inline-block;
  width: 100%;
}

.mat-expansion-panel-header-description,
.mat-expansion-panel-header-title {
  flex-flow: row;
  justify-content: space-between;
}

.mat-expansion-panel .mat-expansion-panel-header.mat-expansion-toggle-indicator-before {
  align-items: flex-start;
  padding: 15px 19px 15px 24px;
  .mat-expansion-indicator {
    margin: -2px 16px 0 0;
    display: inline-block;
    vertical-align: top;
    &::after {
      content: '\231F';
      display: inline-block;
      transform: rotate(45deg);
      vertical-align: top;
      font-size: 30px;
      line-height: 30px;
      border: none;
    }
  }
}

.mat-form-field-disabled .mat-form-field-outline {
  background: #f6f6f6;
}

.mat-input-element {
  &:hover,
  &:active,
  &:focus {
    color: inherit;
    outline: $primary-color;
    background-color: inherit;
    border: 0 inherit;
  }
}

.mat-tree-node {
  color: $primary-color;

  .tree-node-label {
    font-size: 16px;
    line-height: 22px;
    margin-left: 35px;

    &[tree-node-selected="true"] {
      font-weight: bold;
      border-color: $primary-color;
    }
  }

  .mat-checkbox-inner-container {
    display: none;
  }
}

.mat-icon .a {
  fill: none;
}

.mat-step-header .mat-step-icon-state-done {
  background-color: green;
}

.mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-arrow {
  // opacity: 0.54 !important;
  // transform: translateY(0px) !important;
}

.mat-dialog-container {
  padding: 0;
  border-radius: 0 !important;
}

.mat-table {
  tfoot {
    z-index: 1 !important;
  }
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: $primary-color;
}

.mat-button-disabled {
  background-color: initial !important;
  border: initial !important;
}

.translated-paragraph {
  white-space: pre-wrap;
  width: 1000px !important;
}

.mat-form-field-wrapper {
  padding: 0 !important;
  margin: 0 !important;
}

.mat-form-field-subscript-wrapper {
  top: inherit !important;
  margin-top: 0.3em !important;
  letter-spacing: 0 !important;
  font-size: 12px !important;
}

.mat-form-field-label {
  letter-spacing: 0 !important;
  font-weight: 700 !important;
  font-size: 15px !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.5rem !important;
}

mat-form-field {
  font-size: 16px !important;
  min-width: 250px !important;
  letter-spacing: 0 !important;
}

.mat-form-field-infix {
  border-top-width: 0.5em;
}

.mat-select-panel {
  min-width: fit-content !important;
}

.mat-form-field.mat-paginator-page-size-select {
  font-size: inherit !important;
  min-width: inherit !important;

  & .mat-form-field-wrapper {
    margin: initial !important;
    padding: initial !important;
    padding-bottom: 1.25em !important;
  }
}

.mat-form-field-label-wrapper {
  top: -0.55em !important;
  padding-top: 0.55em !important;
}

.mat-form-field-empty.mat-form-field-label {
  top: 1.34375em !important;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  top: 1.8em !important;
}

.mat-slide-toggle.mat-disabled .mat-slide-toggle-label, .mat-slide-toggle.mat-disabled .mat-slide-toggle-thumb-container {
  cursor: not-allowed !important;
}

.mat-form-field.ng-dirty.ng-invalid .mat-form-field-label {
  color: #717171 !important;
}

button.mat-calendar-body-cell {
  padding: inherit !important;
  vertical-align: inherit !important;
  border-radius: inherit !important;
  display: inherit !important;
  border: inherit !important;

  &:hover, &:focus {
    background-color: transparent;
  }
}

td.mat-calendar-body-cell-container {
  padding-left: inherit !important;
  padding-right: inherit !important;
}

@mixin checkbox-color($color) {
  .mat-checkbox-frame {
    border-color: $color;
  }

  &.mat-checkbox-checked .mat-checkbox-checkmark-path {
    stroke: $color !important;
  }
}

mat-checkbox {
  .mat-checkbox-background {
    background-color: transparent !important;
  }

  &.mat-primary {
    @include checkbox-color($primary-color);
  }

  &.mat-warn {
    @include checkbox-color($warn-darker-color)
  }

  &.mat-accent {
    @include checkbox-color($primary-color)
  }
}

.mat-form-field-invalid .mat-input-element {
  color: $warn-darker-color !important;
}

.mat-form-field-invalid {
  background-color: $warn-lighter-color !important;
}

.mat-form-field.ng-dirty.ng-invalid .mat-form-field-label {
  color: $warn-darker-color !important;
}

.mat-form-field-prefix .mat-icon, .mat-form-field-suffix .mat-icon {
  font-size: 100% !important;
}

.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0 !important;
}

.mat-tooltip {
  white-space: pre-line !important;
}

/* stylelint-enable */
