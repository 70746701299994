/* stylelint-disable */

@import 'src/app/style/material';
@import 'src/app/style/form';
@import 'src/app/style/button';
@import 'src/app/style/sticky-header';
@import 'src/app/style/sidenav';
@import 'src/app/style/utility/utility';
@import 'src/app/style/utility/variables';

.run-popover {
  background-color: rgba(0, 0, 0, 0.70);
  border-radius: 5px;
  color: #fff;
  padding: 5px 10px;

  & span {
    display: block;
  }
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  user-select: none;
}

[hidden] {
  display: none !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #667790;
  opacity: 1; /* Firefox */
  font-size: 16px;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #667790;
  font-size: 16px;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #667790;
  font-size: 16px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
}

/* Scrollbar */

/* Width */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #717171;
  border-radius: 5px;
}

body {
  margin: 0;
  --warn-color: #{$warn-darker-color};
}

.main-container {
  input:focus,
  input:active,
  input:hover,
  select:focus,
  select:active,
  select:hover,
  textarea:focus,
  textarea:active,
  textarea:hover {
    border: none;
  }

  // button:hover,
  // button:focus,
  // button:active,
  .eis-btn-primary:hover,
  .eis-btn-primary:focus,
  .eis-btn-primary:active {
    background-color: unset;
    outline: unset;
    border: unset;
    color: unset;
  }
}

a {
  color: #5738ff !important;
}

.cdk-overlay-backdrop {
  z-index: 0 !important;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.cursor-disabled {
  cursor: not-allowed;
}

.pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.chips {
  background-color: #4178BC;
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
  align-items: center;
  color: #fff;
}

ngx-global-contact-footer {
  display: block;
  padding: 25px;
}

global-nav {
  position: fixed;
  width: 100%;
  z-index: 999;
}

lib-ngx-deeplinker {
  margin: 0 !important;
  display: block;
  text-align: right;

  button {
    background-color: #fff;
    color: $primary-color;
    border: 1px solid $primary-color;
  }

  ::ng-deep button {
    height: 26px !important;
    padding: 4px 20px !important;
  }
}

.a {
  fill: none !important;
}

button:disabled {
  cursor: not-allowed !important;
  pointer-events: auto;
  background-color: #d8dadc !important;
  color: #999 !important;
  opacity: 1;

  &:hover {
    opacity: 1;
    border: 1px solid #999;
  }
}

button.mat-focus-indicator.mat-icon-button:hover,
button.mat-focus-indicator.mat-icon-button:focus {
  border: none !important;
}

.button-toggle.mat-button-toggle-checked {
  button:hover, button:focus {
    background-color: $primary-color;
    border: none;
    color: #ffffff;
  }
}

.button-toggle {
  button, button:hover, button:focus {
    background-color: #ffffff;
    border: none;
    color: $primary-color;
  }
  button:disabled {
    border: none;
    &:hover {
      border: none;
    }
  }

  &.active {
    button {
      background-color: $primary-color;
      color: #fff;
    }
  }
}

input.mat-select-search-input {
  width: 100% !important;
  border-radius: 18px !important;
  border-color: #d4d4d4 !important;
  padding-left: 3rem !important;
  font-size: 14px !important;

  &:hover,
  &:focus {
    border: 1px solid #d4d4d4 !important;
  }
}

.map-container {
  margin: 2rem auto 1rem;
}

.pac-container {
  min-width: 400px !important;
}

.section-header-container {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 15px;
  border-bottom: 1px #ddd solid;
  padding: 0 0 1rem;
}

.section-header {
  color: $primary-color;
  font-weight: 400;
}

.mat-select-search-input-container {
  position: sticky;
  background-color: #FFFFFF;
  top: 0;
  z-index: 1;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  .icon-search {
    width: 15px;
    margin-left: 1rem;
    position: absolute;
    color: transparent;
  }
}

.page-container {
  padding: 0 2rem 2rem;
}

map-view {
  height: 20rem;
}

th,
td {
  padding: 0 2rem;
}

.mat-header-row {
  background-color: #fff;

  th {
    color: $primary-color;
    font-weight: 600;
    font-size: 14px;
    max-width: 11rem;
  }
}

.snack-style-yellow {
  display: none !important;
}

/* stylelint-enable */
